import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import CarouselStyles from '../styles/carouselStyles';

const CarouselContainer = styled.div`
  margin: ${props => props.margin || '0 0 0 0'};
  width: 100%;
`;

const CarouselInnerContainer = styled.div`
  width: 100%;
  // height: 100vh;
  // padding: 160px 0;

  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  // flex-direction: column;
`;

const CarouselImageContainer = styled.div`
  // max-height: calc(100vh - 160px);
  // height: 100%;
  // width: calc(100% - 108px);
  // display: flex;
  // align-items: center;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.666%;
  }
`;

const PortraitImage = styled.img`
  object-fit: contain;
  // max-height: 100%;
  // max-height: calc(100vh - 139px - 54px - 54px);
  // padding: 54px 0;
  // max-height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;

  // max-height: calc(100vh - 140px);
  margin: 0 auto;
`;
const LandscapeImage = styled.img`
  // object-fit: contain;
  height: 100%;
  // max-height: 100%;
  // padding: 54px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  // object-position: center;
  width: 100%;
  height: 100%;
  // max-height: calc(100vh - 140px);
  // margin: 0 auto;
`;

const Caption = styled.p`
  font-size: 14px;
  color: #000;
  margin-top: 30px;
`;

const CaptionCount = styled.span`
  font-variant-numeric: diagonal-fractions;
`;

class DefaultGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      totalSlides: 0,
    };

    this.updateCurrentSlide = this.updateCurrentSlide.bind(this);
  }

  componentDidMount(props) {
    this.setState({
      totalSlides: this.props.images.length,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.images.length !== prevProps.images.length) {
      this.setState({
        totalSlides: this.props.images.length,
      });
    }
  }

  // next = () => {
  //   this.setState(state => ({
  //     currentSlide: state.currentSlide + 1,
  //   }));
  // };

  // prev = () => {
  //   this.setState(state => ({
  //     currentSlide: state.currentSlide - 1,
  //   }));
  // };

  updateCurrentSlide = index => {
    const { currentSlide } = this.state.currentSlide;
    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  render(props) {
    const galleryImages = this.props.images
      .filter(image => image.gallery_image.url !== null)
      .map((imageData, index) => {
        let imageHeight = imageData.gallery_image.dimensions.height;
        let imageWidth = imageData.gallery_image.dimensions.width;

        if (imageHeight > imageWidth) {
          return (
            <CarouselInnerContainer key={index}>
              <CarouselImageContainer>
                <PortraitImage
                  src={imageData.gallery_image.url}
                  // srcSet={`${imageData.gallery_image.url}&h=400&crop=entropy&q=80 1x,
                  // ${imageData.gallery_image.url}&h=400&crop=entropy&dpr=2&q=80 2x,
                  // ${imageData.gallery_image.url}&h=400&crop=entropy&dpr=3&q=80 3x`}
                  // src={`${imageData.gallery_image.url}&h=400&crop=entropy&q=80`}
                  alt={imageData.gallery_image.alt}
                />
              </CarouselImageContainer>
              {/* <Caption>
              <CaptionCount>
                {this.state.currentSlide +
                  1 +
                  '/' +
                  this.state.totalSlides +
                  ' '}
              </CaptionCount>
              {imageData.gallery_image.copyright}
            </Caption> */}
            </CarouselInnerContainer>
          );
        } else {
          return (
            <CarouselInnerContainer key={index}>
              <CarouselImageContainer>
                <LandscapeImage
                  src={imageData.gallery_image.url}
                  // srcSet={`${imageData.gallery_image.url}&h=400&crop=entropy&q=80 1x,
                  // ${imageData.gallery_image.url}&h=400&crop=entropy&dpr=2&q=80 2x,
                  // ${imageData.gallery_image.url}&h=400&crop=entropy&dpr=3&q=80 3x`}
                  // src={`${imageData.gallery_image.url}&h=400&crop=entropy&q=80`}
                  alt={imageData.gallery_image.alt}
                />
              </CarouselImageContainer>
              {/* <Caption>
              <CaptionCount>
                {this.state.currentSlide +
                  1 +
                  '/' +
                  this.state.totalSlides +
                  ' '}
              </CaptionCount>

              {imageData.gallery_image.copyright}
            </Caption> */}
            </CarouselInnerContainer>
          );
        }
      });

    return (
      <CarouselContainer margin={this.props.margin}>
        <CarouselStyles />
        <Carousel
          showThumbs={false}
          showIndicators={false}
          infiniteLoop={true}
          centerMode={true}
          centerSlidePercentage={100}
          swipeable={true}
          useKeyboardArrows
          emulateTouch
          showStatus={false}
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
        >
          {galleryImages}
        </Carousel>
      </CarouselContainer>
    );
  }
}

export default DefaultGallery;
