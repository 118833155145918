import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Line from '../utility/line';
import DefaultGallery from '../images/defaultGallery';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

const PublicationDetails = styled.h3`
  font-size: 28px;

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const PublicationTitle = styled.h1`
  font-size: 28px;
  text-align: center;

  margin: 30px 0;

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const TextContainer = styled.div`
  max-width: 655px;
  text-align: left;

  margin: ${props => props.margin || '0 auto'};

  font-size: 28px;

  a {
    color: red;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const PublicationContainer = styled.div`
  margin-bottom: 40px;
`;

const BuyButton = styled.div`
  line-height: 1;
  -webkit-appearance: none;

  & input[type='submit'],
  & a {
    -webkit-appearance: none;
    margin: -4px 0 -4px 47px;
    padding: 6px 9px 6px 8px;
    text-decoration: none;
    line-height: 1;
    color: #000;
    background-color: #fff;
    border: 2px solid #000;
    cursor: pointer;
    border-radius: 0px;
    &:focus {
      outline: none;
    }
    &:hover {
      color: #fff;
      background-color: #000;

      border: 2px solid rgba(255, 255, 255, 0);
    }
  }
`;

const BuyButtonDisabled = styled(BuyButton)`
  cursor: auto;
  & input[type='submit'],
  & a {
    cursor: default;
    pointer-events: none;
    &:hover {
      color: #000;
      background-color: #fff;

      border: 2px solid #000;
    }
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Publication = ({ data: { prismicPublication } }) => {
  const { data } = prismicPublication;

  return (
    <>
      <Helmet
        title={`${data.title.text !== '' ? data.title.text + '-' : ''}  ${
          data.author !== null ? data.author + ' -' : ''
        } Askeaton Contemporary Arts`}
      />
      <Col col={12}>
        <Row justifyContent="center">
          {data.gallery && (
            <Col col={12} md={10}>
              <DefaultGallery images={data.gallery} />
            </Col>
          )}

          <Col col={12}>
            <PublicationContainer>
              <PublicationTitle>
                {data.author}
                <br />
                {data.title.text}
              </PublicationTitle>

              <TextContainer
                margin={'0 auto 40px auto'}
                dangerouslySetInnerHTML={{
                  __html: data.text.html,
                }}
              />

              <TextContainer>
                {data.year && (
                  <>
                    <PublicationDetails>{data.year}</PublicationDetails>
                    <Line margin={'22px 0'} color={'#000'} />
                  </>
                )}

                {data.pages && (
                  <>
                    <PublicationDetails>{data.pages}</PublicationDetails>
                    <Line margin={'22px 0'} color={'#000'} />
                  </>
                )}

                {data.dimensions && (
                  <>
                    <PublicationDetails>{data.dimensions}</PublicationDetails>
                    <Line margin={'22px 0'} color={'#000'} />
                  </>
                )}

                {data.print_details && (
                  <>
                    <PublicationDetails>
                      {data.print_details}
                    </PublicationDetails>
                    <Line margin={'22px 0'} color={'#000'} />
                  </>
                )}

                {data.secondary_print_details && (
                  <>
                    <PublicationDetails>
                      {data.secondary_print_details}
                    </PublicationDetails>
                    <Line margin={'22px 0'} color={'#000'} />
                  </>
                )}

                {data.isbn && (
                  <>
                    <PublicationDetails>{data.isbn}</PublicationDetails>
                    <Line margin={'22px 0'} color={'#000'} />
                  </>
                )}

                {data.price && (
                  <>
                    <PublicationDetails>
                      <PriceContainer>
                        {data.price}{' '}
                        {data.paypal_link_active === false ? (
                          data.buy_download === false ? (
                            <BuyButton>
                              <form
                                target="paypal"
                                action="https://www.paypal.com/cgi-bin/webscr"
                                method="post"
                              >
                                <input
                                  type="hidden"
                                  name="shopping_url"
                                  value="https://askeatonarts.com/publications/"
                                />
                                <input
                                  type="hidden"
                                  name="cmd"
                                  value="_s-xclick"
                                />
                                <input
                                  type="hidden"
                                  name="hosted_button_id"
                                  value={data.paypal_button_id}
                                />
                                <input
                                  type="submit"
                                  border="0"
                                  name="submit"
                                  alt={data.paypal_button_text}
                                  value={data.paypal_button_text}
                                />
                                <img
                                  alt=""
                                  border="0"
                                  src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                                  width="1"
                                  height="1"
                                />
                              </form>
                            </BuyButton>
                          ) : (
                            <BuyButton>
                              <a href={data.paypal_link.url} target=" _blank">
                                {data.paypal_button_text}
                              </a>
                            </BuyButton>
                          )
                        ) : (
                          <BuyButtonDisabled disabled>
                            <a href="#" disabled>
                              {data.paypal_button_text}
                            </a>
                          </BuyButtonDisabled>
                        )}
                      </PriceContainer>
                    </PublicationDetails>
                    <Line margin={'22px 0'} color={'#000'} />
                  </>
                )}
              </TextContainer>
            </PublicationContainer>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Publication;

export const pageQuery = graphql`
  query PublicationBySlug($uid: String!) {
    prismicPublication(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        author
        text {
          html
        }
        year
        pages
        dimensions
        print_details
        secondary_print_details
        isbn
        price
        paypal_link_active
        paypal_button_text
        paypal_button_id
        buy_download
        paypal_link {
          url
        }
        gallery {
          gallery_image {
            dimensions {
              width
              height
            }
            url
            alt
          }
        }
      }
    }
  }
`;
