import React from 'react';
import styled from 'styled-components';

const LineElement = styled.hr`
  border: 0;
  border-bottom: 2px solid ${props => props.color || '#ffe100'};
  margin: ${props => props.margin || '0'};
  width: 100%;
`;

const Line = props => <LineElement margin={props.margin} color={props.color} />;

export default Line;
